import React from 'react';

// libraries
import { PageRendererProps } from 'gatsby';
import { css } from '@emotion/core';

import Head from 'components/Head';
import MainLayout from 'components/MainLayout';

import background from 'images/404.svg';

export default function Refund(props: PageRendererProps) {
  const { location } = props;

  const params = new URLSearchParams(location.search);
  const succeeded = !params.has('error');

  const title = succeeded ? 'Refund Requested' : 'Refund Error';
  const copy = succeeded
    ? 'You will receive your refund in 5–10 business days. Thanks for using Parsec for cloud gaming.'
    : 'Your refund is already processing.';

  return (
    <MainLayout>
      <Head title={title} />
      <section css={hero}>
        {title && <h2 css={header}>{title}</h2>}
        {copy && <p>{copy}</p>}
      </section>
    </MainLayout>
  );
}

const hero = css`
  flex: 1;
  background: center url(${background}) no-repeat;
  background-size: cover;
  text-align: center;
  min-height: 60rem;
  padding: 6rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const header = css`
  margin-bottom: 1.2rem;
`;
